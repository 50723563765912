<!--
 * @Author: jiaaolei 1137400804@qq.com
 * @Date: 2024-09-07 16:34:50
 * @LastEditors: jiaaolei 1137400804@qq.com
 * @LastEditTime: 2024-09-07 16:37:31
 * @FilePath: \jin_dong\src\views\anliShow.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="container">
        <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 1" src="../assets/img/splash.jpg" @click="gotoMain">
        <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 0" src="../assets/img/main.png" >
        <img :class="mode=='landscape'?'wall-landscape':'wall-portrait'"  v-show="stage === 0 && step1 === true" src="../assets/img/wall1.png" >
        <img :class="mode=='landscape'?'wall-landscape':'wall-portrait'"  v-show="stage === 0 && step2 === true" src="../assets/img/wall2.png" >
        <img :class="mode=='landscape'?'wall-landscape':'wall-portrait'"  v-show="stage === 0 && step3 === true" src="../assets/img/wall3.png" >
        <img :class="mode=='landscape'?'wall-landscape':'wall-portrait'"  v-show="stage === 0 && step4 === true" src="../assets/img/wall4.png" >
        <img :class="mode=='landscape'?'wall-landscape':'wall-portrait'"  v-show="stage === 0" src="../assets/img/wall.png" >
        <!-- <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 0" src="../assets/img/wall2.png" >
        <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 0" src="../assets/img/wall3.png" >
        <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 0" src="../assets/img/wall4.png" >
        <img :class="mode=='landscape'?'image-landscape':'image-portrait'" v-show="stage === 0" src="../assets/img/wall.png" > -->
        <video
            v-show="stage === 9"
            ref="veoRef"
            :class="mode=='landscape'?'u-video-landscape':'u-video-portrait'"
            :src="videoSrc"
            :autoplay="auto"
            @ended="playend"
            crossorigin="anonymous"
            >
            您的浏览器不支持video标签。
        </video>
        <audio
        ref="audioRef1"
        :src="loopAudio1"
        volume="0.2"
        loop
        ></audio>
        <audio
        ref="audioRef2"
        :src="loopAudio2"
        volume="0.2"
        loop
        ></audio>
        <img :class="mode=='landscape'?'back-landscape':'back-portrait'" v-show="stage === 9" src="../assets/img/back.png" @click="goBack"/>
        <div v-show="stage === 0" :class="mode=='landscape'?'floatBtn1-landscape':'floatBtn1-portrait'" @click="playVideo(0)"></div>
        <div v-show="stage === 0" :class="mode=='landscape'?'floatBtn2-landscape':'floatBtn2-portrait'" @click="playVideo(1)"></div>
        <div v-show="stage === 0" :class="mode=='landscape'?'floatBtn3-landscape':'floatBtn3-portrait'" @click="playVideo(3)"></div>
        <div v-show="stage === 0" :class="mode=='landscape'?'floatBtn4-landscape':'floatBtn4-portrait'" @click="playVideo(2)"></div>
    </div>
    
</template>
<script>
import vedio1 from '@/assets/img/part1.mp4'
import vedio2 from '@/assets/img/part2.mp4'
import vedio3 from '@/assets/img/part3.mp4'
import vedio4 from '@/assets/img/part4.mp4'
import audio1 from '@/assets/img/audio1.mp3'
import audio2 from '@/assets/img/audio2.mp3'

export default {
    components:{

    },
    data(){
        return {
            stage: 1,
            stepIndex: 0,
            step1: false,
            step2: false,
            step3: false,
            step4: true,
            videoSrc: vedio1,
            loopAudio1: audio1,
            loopAudio2: audio2,
            auto: false,
            mode: 'landscape'
        }
    },
    mounted(){
		let width = window.innerWidth
		let height = window.innerHeight
		if (width < height) {
			this.mode = 'landscape'
		} else {
			this.mode = 'portrait'
		}
	},
    methods:{
        playVideo(index) {
            console.log(index)
            this.stage = 9
            if (index == 0) {
                this.videoSrc = vedio1
                // this.loopAudio = audio1
                this.$refs.audioRef1.play()
                this.$refs.audioRef2.pause()
            } else if (index == 1) {
                this.videoSrc = vedio2
                // this.loopAudio = audio2
                this.$refs.audioRef2.play()
                this.$refs.audioRef1.pause()
            } else if (index == 2) {
                this.videoSrc = vedio3
                // this.loopAudio = audio2
                this.$refs.audioRef2.play()
                this.$refs.audioRef1.pause()
            } else if (index == 3) {
                this.videoSrc = vedio4
                // this.loopAudio = audio1
                this.$refs.audioRef1.play()
                this.$refs.audioRef2.pause()
            }
            this.stepIndex = index + 1
            this.$refs.veoRef.currentTime = 0;
            this.$refs.veoRef.play()
            this.auto = true
        },
        goBack() {
            this.$refs.veoRef.pause()
            this.stage = 0
        },
        playend(e) {
            console.log('playend')
            console.log(e)
            switch (this.stepIndex) {
                case 1:
                    this.step1 = true
                    break;
                case 2:
                    this.step2 = true
                    break;
                case 3:
                    this.step3 = true
                    break;
                case 4:
                    this.step4 = true
                    break;
                default:
                    break;
            }
        },
        gotoMain() {
            this.stage = 0
            this.$refs.audioRef1.play()
        }
    }
}
</script>
<style>
.container {
    display: flex;
    justify-content: center; 
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.u-video-portrait {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.u-video-landscape {
    object-fit: cover;
    width: 100vh;
    height: 100vw;
    transform-origin: center;
    transform: rotate(90deg);
}
.floatBtn1-portrait {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    left: 30%; /* 距离视口右侧10像素 */
    top: 42%; /* 距离视口底部10像素 */
    /* background-color: yellow; */
    /* opacity: 0.5; */
    width: 15%;
    height: 15rem;
    
}
.floatBtn2-portrait {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    left: 42%; /* 距离视口右侧10像素 */
    top: 10%; /* 距离视口底部10像素 */
    /* background-color: blue; */
    /* opacity: 0.5; */
    width: 22%;
    height: 25rem;
}
.floatBtn3-portrait {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    right: 25%; /* 距离视口右侧10像素 */
    bottom: 30%; /* 距离视口底部10像素 */
    /* background-color: orange; */
    /* opacity: 0.5; */
    width: 10%;
    height: 15rem;
}
.floatBtn4-portrait {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    right: 1%; /* 距离视口右侧10像素 */
    top: 15%; /* 距离视口底部10像素 */
    /* background-color: lightcoral; */
    /* opacity: 0.5; */
    width: 16%;
    height: 65rem;
}

.floatBtn1-landscape {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    right: 43%; /* 距离视口右侧10像素 */
    top: 32%; /* 距离视口底部10像素 */
    /* background-color: yellow; */
    /* opacity: 0.5; */
    width: 15%;
    height: 12rem;
    
}
.floatBtn2-landscape {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    right: 14%; /* 距离视口右侧10像素 */
    top: 45%; /* 距离视口底部10像素 */
    /* background-color: blue; */
    /* opacity: 0.5; */
    width: 25%;
    height: 12rem;
}
.floatBtn3-landscape {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    left: 30%; /* 距离视口右侧10像素 */
    bottom: 28%; /* 距离视口底部10像素 */
    /* background-color: orange; */
    /* opacity: 0.5; */
    width: 20%;
    height: 10rem;
}
.floatBtn4-landscape {
    position: fixed; /* 固定定位，相对于视口 */
    z-index: 1001; /* 确保它在其他元素之上 */
    cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
    right: 20%; /* 距离视口右侧10像素 */
    bottom: 10%; /* 距离视口底部10像素 */
    /* background-color: red; */
    /* opacity: 0.5; */
    width: 65%;
    height: 13rem;
}
.back-portrait {
  position: fixed; /* 固定定位，相对于视口 */
  right: 20px; /* 距离视口右侧10像素 */
  bottom: 20px; /* 距离视口底部10像素 */
  cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
  z-index: 1000; /* 确保它在其他元素之上 */
}

.back-landscape {
  position: fixed; /* 固定定位，相对于视口 */
  right: 20px; /* 距离视口右侧10像素 */
  bottom: 12%; /* 距离视口底部10像素 */
  cursor: pointer; /* 鼠标悬停时显示指针，表示可以点击 */
  z-index: 1000; /* 确保它在其他元素之上 */
  transform-origin: center;
    transform: rotate(90deg);
}


.image-portrait {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.image-landscape {
    object-fit: contain;
    width: 100vh;
    height: 100vw;
    transform-origin: center;
    transform: rotate(90deg);
    /* background-color: blueviolet; */
}

.wall-portrait {
    position: fixed;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.wall-landscape {
    position: fixed;
    object-fit: contain;
    width: 100vh;
    height: 100vw;
    transform-origin: center;
    transform: rotate(90deg);
}
</style>